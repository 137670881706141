// @ts-ignore
import * as mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

export class GeolocateControlWrapper extends mapboxgl.GeolocateControl {
  constructor(options: any) {
    super(options)
  }
  _updateCamera(position: any) {
    //@ts-ignore
    this._map.jumpTo({
      center: [position.coords.longitude, position.coords.latitude],
      zoom: 17,
    })
  }
}
