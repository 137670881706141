import { FC } from 'react'
import { modalPropInterface } from '../../utilities/modalInterfaces'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { DroneEntry } from '../../../store/slices/dronesSlice/dronesSlice'
import { differenceInMinutes } from 'date-fns';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify'
import './InfoContent.scss'






const TypographyRow: FC<{ label: any, value: any }> = ({ label, value }) => {
  return <Typography>
    <span>
      <span style={{ fontWeight: 'bold' }}>{label}: </span>
      <span>{value}</span>
    </span>
  </Typography>
}

const InfoContent: FC<modalPropInterface> = ({ uniqueProps }) => {
  const { t } = useTranslation()
  
  const data: DroneEntry = uniqueProps?.rowData
  const startDate = new Date(data.start)
  const stopDate = new Date(data.stop)
  const duration = differenceInMinutes(stopDate, startDate)

  const getCopyToClipboardFn = (input: string, type_info_notification: string) => {
    return (event: any) => {
      event.stopPropagation()
      navigator.clipboard.writeText(input)
        .then(() => {
          toast.info(t('copied_to_clipboard' , { what: type_info_notification }))
        })
        .catch(err => {
          toast.error(t('copy_to_clipboard_error', { error_message: err.message }));
        });
    }
  };

  return <DialogContent>
    <Typography onClick={getCopyToClipboardFn(`${data.geojson.coordinates[1]}, ${data.geojson.coordinates[0]}`, t('cordinates_format_dd'))} className="coordinates-copy"  >{t('copy_coordinates')} <ContentCopyIcon/> </Typography>
    <TypographyRow label={t('lat')} value={data.geojson.coordinates[1]} />
    <TypographyRow label={t('lon')} value={data.geojson.coordinates[0]} />
    <TypographyRow label={t('max_height')} value={data.aglmax} />
    <TypographyRow label={t('start_time')} value={startDate.toLocaleString()} />
    {/* <TypographyRow label={t('stop_time')} value={stopDate.toLocaleString()} /> */}
    <TypographyRow label={t('declared_duration')} value={duration} />
  </DialogContent>
}

export default InfoContent
