import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'

export interface cleanState {
  value: boolean
}

const initialState: cleanState = {
  value: false
}

export const cleanSlice = createSlice({
  name: 'clean',
  initialState,
  reducers: {
    setClean: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload
    },
  },
})

export const { setClean } = cleanSlice.actions

export const selectClean = (state: RootState) => state.clean.value

export default cleanSlice.reducer
