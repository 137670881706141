/* eslint-disable no-console */
import elevationAPI from '../apis/ElevationAPI'
import { replaceSubCategory } from './functions'
import { FlightData } from './sharedInterfacesAndTypes'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import axiosDroneMapClient from '../apis/AxiosDroneMapClient'

const eventCheckConflicts = async (flightData: FlightData, lat: number, lng: number, hgtElevation: number) => {
  const requestBody = {
    start_time: new Date(),
    max_height: Math.round(flightData.max_height + hgtElevation),
    lon: lng,
    lat: lat,
    range: 1, //switched temporary to constant value
    mass: flightData.mass,
    flight_type: flightData.flight_type,
    flight_category: flightData.flight_category,
    flight_subcategory: replaceSubCategory(flightData.flight_subcategory),
  }

  return axiosDroneMapClient
    .getEventCheckConflicts(requestBody)
    .then((checkConflictsResponse) => {
      checkConflictsResponse.hgtElevation = hgtElevation;
      return checkConflictsResponse;
    })
}

export const getConflictZones = (flightData: FlightData, lat: number, lng: number): Promise<any> => {
  return elevationAPI
    .getElevation(lat, lng)
    .then((hgtElevation) => {
      if (hgtElevation === null)
        return { conflictedZones: [], requirements: [], hgtElevation: 0 };
      return eventCheckConflicts(flightData, lat, lng, hgtElevation)
    })
}

export const getNotams = () => {
  return axiosDroneMapClient
    .getNotams()
    .then(function (notams: any) {
      const startOfDay = moment().startOf('days')
      const startOfNextDay = moment().add(1, 'days').startOf('day')
      const chronologicalNotamsWithActivationThisDay = notams
        .filter((notam: any) => {
          if (!!notam?.b && !!notam?.c) {
            const startTm = moment(notam.b)
            const stopTm = moment(notam.c)
            const isActive = startTm.valueOf() < startOfNextDay.valueOf() && stopTm.valueOf() > startOfDay.valueOf()

            if (!isActive) {
              // console.warn(`Notam for zone ${notam.zonename} is not for this day`, notam)
              return false
            }
          }
          // if (notam?.d === 'H24') {
          //   console.info(`Notam for zone ${notam.zonename} is H24`, notam)
          // }

          notam.notamActivations = notam.notamActivations || []
          notam.notamActivationsThisDay = []
          const hasNotamActivations = !isEmpty(notam.notamActivations)

          if (hasNotamActivations) {
            notam.notamActivations.sort((a: any, b: any) => moment(a.start).valueOf() - moment(b.start).valueOf())
            notam.notamActivationsThisDay = notam.notamActivations.filter((notAct: any) => {
              const startTm = moment(notAct.start)
              const stopTm = moment(notAct.stop)

              return startTm.valueOf() < startOfNextDay.valueOf() && stopTm.valueOf() > startOfDay.valueOf()
            })
            return true
          }

          // if (isEmpty(notam.notamActivationsThisDay)) {
          //   console.warn(`Notam for zone ${notam.zonename} has no activation this day`, notam)
          // }

          return true
        })
        .sort((a: any, b: any) => moment(b.modified).valueOf() - moment(a.modified).valueOf())

      return chronologicalNotamsWithActivationThisDay
    })
}
