import { FC, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { modalPropInterface } from '../../utilities/modalInterfaces'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import axiosDroneMapClient from '../../../apis/AxiosDroneMapClient'
import './KmlDownload.scss'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface CheckedItemsState {
  [key: string]: boolean
}

interface AirspaceTypes {
  [key: string]: Array<string>
}

const airspaceTypes: AirspaceTypes = {
  'DRA-I': ['DRAI', 'ADIZ', 'AREA', 'RMZ', 'NW'],
  'DRA-R': [
    'DRAR', 'ATZ', 'ATZ1KM', 'ATZ6KM', 'CTR', 'CTR1KM',
    'CTR6KM', 'MCTR', 'MCTR2KM', 'D', 'P', 'TFR', 'RPA', 'TRA', 'R',
  ],
  'DRA-P': ['DRAP', 'TSA', 'MRT', 'TMA', 'MTMA'],
}

const renamedAirspaceTypes: {
  [key: string]: string
} = {
  'DRAP': 'DRA-P',
  'DRAI': 'DRA-I',
  'DRAR': 'DRA-R',
}

const KmlDownload: FC<modalPropInterface> = () => {
  const { t } = useTranslation()

  const [height, setHeight] = useState('120')
  const [zones, setZones] = useState<CheckedItemsState>(
    Object.values(airspaceTypes).reduce(
      (acc, type) => {
        return {
          ...acc,
          ...type.reduce(
            (acc, cur) => {
              acc[cur] = true;
              return acc;
            }, {} as CheckedItemsState)
        };
      }, {} as CheckedItemsState)
  )

  const [extruded, setExtruded] = useState(false)
  const [chooseZones, setChooseZones] = useState(false)

  const zonesCount = Object.keys(zones).length
  const zonesSelectedCount = Object.values(zones).filter(x => x).length
  const zonesSelectedAll = zonesSelectedCount === zonesCount
  const zonesSelectedNone = zonesSelectedCount === 0

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target
    setZones((prev) => ({ ...prev, [name]: checked }))
  }

  function handleZonesToggle() {
    const new_value = zonesSelectedCount <= zonesCount / 2
    setZones((prev) => {
      return Object.keys(prev)
        .reduce((accumulator: any, key: string) => {
          accumulator[key] = new_value;
          return accumulator;
        }, {})
    })
  }

  const handleExtruded = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtruded(event.target.checked)
  }

  const handleChooseZone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChooseZones(event.target.checked)
  }

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(event.target.value)
  }

  function getAllKMLhref(extruded: Boolean) {
    return `/v1/kml?extrude=${extruded}`
  }

  function getChosenKMLhref(extruded: Boolean, zones: any, height: String) {
    const chosenZones = Object.keys(zones).filter((key) => zones[key] === true)
    if (chosenZones.length <= 0)
      return '';
    return `/v1/kml/filtered?extrude=${extruded}&types=${chosenZones}&max=${height}`
  }

  const downloadKmlFromLink = async (fileUrl: string) => {
    try {
      const fileName = `utm-zones-${moment().format('YYYY-MM-DD_HH-mm-ss')}.kml`
      const contentType = 'application/vnd.google-earth.kml+xml'
      const file = await axiosDroneMapClient.getBlobFromFileUrl(fileUrl, contentType)
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  return (
    <DialogContent>
      <div className="kmlDownload">
        <div>
          <FormControl component="fieldset">
            <FormControlLabel label={t('kml_download_3d')} control={
              <Switch checked={extruded} onChange={handleExtruded} />
            } />
            <FormControlLabel label={t('kml_download_choose_zones')} control={
              <Switch checked={chooseZones} onChange={handleChooseZone} />
            } />
          </FormControl>
        </div>
        {!chooseZones &&
          <div className="kmlDownload__section">
            <Button
              variant="contained"
              className="kmlDownload__bigButton"
              onClick={() => downloadKmlFromLink(getAllKMLhref(extruded))}
            >
              <CloudDownloadIcon style={{ marginRight: '8px' }} />
              {t('kml_download_all_zones')}
            </Button>
          </div>
        }
        {chooseZones && <>
          <div className="kmlDownload__section">
            <div
              style={{
                border: '1px solid #ccc',
                padding: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <label htmlFor="heightInput" style={{ marginRight: '10px' }}>
                {t('kml_download_up_to_height')}:
              </label>
              <TextField
                type="number"
                id="heightInput"
                value={height}
                inputProps={{ min: 0, max: 10000, step: 1 }}
                onChange={handleHeightChange}
              />
            </div>
            <div>
              <FormControl component="fieldset" className="kmlDownload__zonesTypes">
                <FormLabel component="legend" className="kmlDownload__formLabel">{t('kml_zone_types')}</FormLabel>
                {Object.keys(airspaceTypes).map((airspaceGroup, index) => (
                  <Box key={index} className="kmlDownload__zonesTypes__box">
                    <FormGroup className="kmlDownload__zonesTypes__group">
                      {airspaceTypes[airspaceGroup].map((airspace, index) => {
                        const airspaceName = renamedAirspaceTypes[airspace] ?? airspace;
                        return <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              className="kmlDownload__zonesTypes__itemCheckbox"
                              checked={!!zones[airspace]}
                              onChange={handleCheckboxChange}
                              name={airspace}
                            />
                          }
                          label={airspaceName}
                        />
                      })}
                    </FormGroup>
                    <Typography className="kmlDownload__zonesTypes__caption" variant="caption">
                      {airspaceGroup}
                    </Typography>
                  </Box>
                ))}
                <FormControlLabel
                  style={{
                    borderTop: '1px solid #ccc',
                  }}
                  control={
                    <Checkbox
                      className="kmlDownload__zonesTypes__itemCheckbox"
                      checked={zonesSelectedAll}
                      indeterminate={!zonesSelectedAll && !zonesSelectedNone}
                      onChange={handleZonesToggle}
                      name="select_deselect_all"
                    />
                  }
                  label={t('select_deselect_all')}
                />
              </FormControl>
            </div>
          </div>
          <div style={{ paddingBottom: '10px' }}>
            <Button
              disabled={zonesSelectedNone}
              variant="contained"
              className="kmlDownload__bigButton"
              onClick={() => downloadKmlFromLink(getChosenKMLhref(extruded, zones, height))}
            >
              <CloudDownloadIcon style={{ marginRight: '8px' }} />
              {t('kml_download_chosen_zones')}
            </Button>
          </div>
        </>}
      </div>
    </DialogContent>
  )
}

export default KmlDownload
