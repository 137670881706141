import { zonesPhoneNumbersInterface } from './sharedInterfacesAndTypes'

export const zonesPhoneNumbers: Array<zonesPhoneNumbersInterface> = [
  { twr: 'EPBY', numbers: ['+48 52 365 4900', '+48 52 324 7145'] },
  { twr: 'EPGD', numbers: ['+48 58 340 7462', '+48 81 452 7462'] },
  { twr: 'EPKK', numbers: ['+48 12 639 7562', '+48 22 574 7562'] },
  { twr: 'EPKT', numbers: ['+48 32 392 7861', '+48 81 452 7861'] },
  { twr: 'EPLB', numbers: ['+48 81 458 1303'] },
  { twr: 'EPLL', numbers: ['+48 42 687 0701', '+48 42 685 7262'] },
  { twr: 'EPMO', numbers: ['+48 22 574 5518', '+48 81 452 5518'] },
  { twr: 'EPPO', numbers: ['+48 61 847 2337'] },
  { twr: 'EPRA', numbers: ['+48 261 511 229'] },
  { twr: 'EPRZ', numbers: ['+48 17 862 2999', '+48 17 227 7672'] },
  { twr: 'EPSC', numbers: ['+48 91 469 7933', '+48 81 452 7933'] },
  { twr: 'EPSY', numbers: ['+48 89 544 3416'] },
  { twr: 'EPWA', numbers: ['+48 22 574 5563', '+48 81 452 5563'] },
  { twr: 'EPWR', numbers: ['+48 71 323 4861', '+48 81 452 4861'] },
  { twr: 'EPZG', numbers: ['+48 68 351 1343'] },
]

export const allTypesWhichOpenCheckin = [
  'S_ENTER_CHECKIN_DATA',
  'S_AWAIT_START_FLIGHT',
  'S_END',
  'S_ENTER_PHONE_NO',
  'S_SHOW_IN_FLIGHT_VIEW',
  'S_AWAIT_FLIGHT_ACCEPTANCE',
  'S_ENTER_AUTH_CODE',
]
