export class ActivationDetails {
  // this instance means that theres is no activation definition at all. It is something different that having activitity defined and being inactive
  public static NO_ACTIVATION = new ActivationDetails(false, false, undefined, undefined, undefined);

  private readonly _isActive: boolean;
  private readonly _h24Active: boolean;
  private readonly _notamActivationsThisDay: any;
  private readonly _webcatActivationsThisDay: any;
  private readonly _aixmActivationsThisDay: any;

  public constructor(isActive: boolean,
    h24Active: boolean,
    notamActivationsThisDay: Array<any> | undefined | null,
    webcatActivationsThisDay: Array<any> | undefined | null,
    aixmActivationsThisDay: Array<any> | undefined | null
  ) {
    this._isActive = isActive;
    this._h24Active = h24Active;
    this._notamActivationsThisDay = notamActivationsThisDay;
    this._webcatActivationsThisDay = webcatActivationsThisDay;
    this._aixmActivationsThisDay = aixmActivationsThisDay;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get h24Active(): boolean {
    return this._h24Active
  }

  public get notamActivationsThisDay(): any {
    return this._notamActivationsThisDay;
  }

  public get webcatActivationsThisDay(): any {
    return this._webcatActivationsThisDay;
  }

  public get aixmActivationsThisDay(): any {
    return this._aixmActivationsThisDay;
  }
}
