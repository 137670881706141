import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { RootState } from '../../index'
import { v4 as uuidv4 } from 'uuid'

export interface modalPropertiesInterface {
  isOpenModal: boolean
  isCloseButtonVisible: boolean
  modalType: string
  title: string
  disableBackdropClick?: boolean
  uniqueProps?: {
    cookieName?: string
    htmlContent?: string | Array<string>
    rowData?: any
    mapType?: string
    flightTime?: string
    resetPhoneNumber?: Function
    onClick?: Function
    filePath?: string
    downloadText?: string
    downloadTextPosition?: string
    openApiTermsOfService?: () => any
    apiKey?: string,
  }
}

export interface extendedModalPropertiesInterface extends modalPropertiesInterface {
  id: string
}

type openedModalsListInterface = Array<extendedModalPropertiesInterface>
interface universalModalStateInterface {
  openedModalsList: openedModalsListInterface
}

const initialState: universalModalStateInterface = { openedModalsList: [] }

export const universalModalSlice = createSlice({
  name: 'universalModal',
  initialState,
  reducers: {
    openUniversalModal: (state, action: PayloadAction<modalPropertiesInterface>) => {
      state.openedModalsList.push({ ...action.payload, id: uuidv4() })
    },
    closeUniversalModal: (state, action: PayloadAction<{ id: string }>) => {
      state.openedModalsList = state.openedModalsList.filter(({ id }) => !isEqual(id, action.payload.id))
    },
  },
})

export const { openUniversalModal, closeUniversalModal } = universalModalSlice.actions

export const selectUniversalModalOpenedList = (state: RootState) => state.universalModal.openedModalsList

export default universalModalSlice.reducer
