import { useEffect, useState } from 'react'
import Map from './components/map/Map'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import { setClean } from './store/slices/cleanSlice/cleanSlice'
import { useAppDispatch } from './store/hooks'
import { useTranslation } from 'react-i18next'
import axiosDroneMapClient from './apis/AxiosDroneMapClient'
import { openUniversalModal } from './store/slices/universalModalSlice/universalModalSlice'

const App: React.FC = () => {
  //real geo position set in map component by mapbox
  const [realLat, setRealLat] = useState(20)
  const [realLon, setRealLon] = useState(20)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: false,
        disableBackdropClick: true,
        modalType: 'custom_info',
        title: t('geolocalization_settings_info_title'),
        uniqueProps: {
          htmlContent: t('geolocalization_settings_info_content'),
          cookieName: 'PreciseLocationInfo',
        },
      })
    )

    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: false,
        disableBackdropClick: true,
        modalType: 'custom_info',
        title: t('informations'),
        uniqueProps: {
          htmlContent: [t('informations_content'), t('cookies_content')],
          cookieName: 'InformationsInfo',
        },
      })
    )

    axiosDroneMapClient
      .login()
      .catch(() => {
        dispatch(setClean(true))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Map
        setRealLat={setRealLat}
        setRealLon={setRealLon}
        realLat={realLat}
        realLon={realLon}
      />
      <ToastContainer limit={3} />
    </>
  )
}

export default App

