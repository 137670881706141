export const conditions_content_pl = `            <p align="justify">
              Struktury przestrzeni powietrznej są dynamiczne. Mogą się zmieniać w czasie i w miejscu. Nowe strefy, mogą
              pojawić się w dowolnym miejscu i czasie. Na pilocie Drona spoczywa obowiązek zapoznania się z aktualnymi
              ograniczeniami.
            </p>
            <br />
            <p align="justify">
              Mapa ma charakter informacyjny i nie zwalnia użytkownika z obowiązku zapoznania się z wszystkimi
              dostępnymi depeszami NOTAM/AUP/UUP. Aby uzyskać najnowsze informacje zadzwoń do AMC Polska +48 22 574
              5733-35
            </p>
            <br />
            <p align="justify">
              Uzyskanie zgody nie zwalnia z obowiązku przestrzegania zasad ruchu lotniczego. Cały lot musi być wykonany
              według obowiązujących przepisów.
            </p>
            <br />
            <p align="justify">
              Zaznaczony w misji rejon lotów nie jest wydzieloną przestrzenią dla Twojej operacji! Zawsze istnieje
              możliwość pojawienia się innego statku powietrznego w tym załogowego, któremu należy bezwzględnie ustąpić
              pierwszeństwa.
            </p>
            <br />
            <p align="justify">
              W strefie geograficznej DRA-I wyznaczonej na potrzeby lotów BVLOS, należy mieć na uwadze, że istnieje
              możliwość pojawienia się innego statku powietrznego.
            </p>
            <br />
            <p align="justify">
              Strefy geograficzne DRA-I informujące o lotach BVLOS są publikowane tylko dla pilotów BSP i nie są
              widziane przez lotnictwo załogowe!
            </p>
            <br />
            <ul>
              <li>
                Akceptując to okno potwierdzasz, że ponosisz odpowiedzialność za zachowanie wymaganych odległości od
                osób, zgromadzeń osób, zabudowy w trakcie misji, określonych w zapisach Rozporządzenia Wykonawczego
                Komisji (UE) 2019/947 z dnia 24 maja 2019r. oraz Wytycznych nr 7 Prezesa Urzędu Lotnictwa Cywilnego z
                dnia 9 czerwca 2021r. w sprawie sposobów wykonywania operacji przy użyciu systemów bezzałogowych statków
                powietrznych w związku z wejściem w życie przepisów rozporządzenia wykonawczego Komisji (UE) nr 2019/947
                z dnia 24 maja 2019 r. w sprawie przepisów i procedur dotyczących eksploatacji bezzałogowych statków
                powietrznych.
              </li>
              <li>
                Akceptując to okno potwierdzasz, że zapoznałeś się ze wszystkimi ograniczeniami w miejscu planowanej
                operacji BSP oraz dostępnymi depeszami NOTAM/AUP/UUP.
              </li>
            </ul>`

    
            
