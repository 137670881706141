import { modalPropInterface, modalsMapInterface } from './modalInterfaces'
import InfoContent from '../components/InfoContent/InfoContent'
import Menu from '../components/Menu/Menu'
import CustomManualPdf from '../components/CustomManualPdf/CustomManualPdf'
import KmlDownload from '../components/KmlDownload/KmlDownload'
import CustomInfo from '../components/CustomInfo/CustomInfo'
import ApiMenu from '../components/ApiMenu/ApiMenu'
import ApiCreateAccount from '../components/ApiCreateAccount/ApiCreateAccount'
import ApiKey from '../components/ApiKey/ApiKey'
import { FunctionComponent } from 'react'

const modalsMap: modalsMapInterface = {
  info: InfoContent,
  menu: Menu,
  kml: KmlDownload,
  custom_info: CustomInfo,
  custom_manual_pdf: CustomManualPdf,
  api_create_account: ApiCreateAccount,
  api_menu: ApiMenu,
  api_key: ApiKey,
}

const getModal = (modalType: string): FunctionComponent<modalPropInterface> | null => {
  return modalsMap[modalType] ?? null
}

export default getModal
