import { useRef } from 'react'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slider from '@mui/material/Slider'
import { InputLabel, MenuItem, Select } from '@mui/material'
import LocalStorageTTL from '../../utilities/localStorage'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'
import './EnterFlightData.scss'
import { isMobile, useMobileOrientation } from 'react-device-detect'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
interface createMarksArrayArgumentsInterface {
  steps: Array<number>
  shouldNotDisplayAllLabels?: boolean
}

function createMarksArray({ steps, shouldNotDisplayAllLabels }: createMarksArrayArgumentsInterface) {
  const isStepsLengthDivisibleByZero = steps.length % 2 === 0
  const isEqualTo = isStepsLengthDivisibleByZero ? 0 : 1

  return steps.map((value, i) => {
    let label = value.toString()

    if (shouldNotDisplayAllLabels && i > 0 && i % 2 === isEqualTo) {
      label = ''
    }

    return {
      value,
      label,
    }
  })
}

export default function EnterFlightData(props: any) {
  const { isPortrait } = useMobileOrientation()
  const shouldNotDisplayAllLabels = isMobile && isPortrait
  const { t } = useTranslation()

  const handleClose = () => {
    props.setOpenFlightData(false)
  }

  const formikRef = useRef<FormikProps<any>>(null)

  const validationSchema = () => {
    return yup.lazy((values: any) => {
      return yup.object({
        range: yup
          .number()
          .typeError(t('numeric_value_required'))
          .integer(t('integer_required'))
          .min(5, t('value_too_low'))
          .max(getRange(values), t('value_too_high'))
          .required(t('value_required')),
        max_height: yup
          .number()
          .typeError(t('numeric_value_required'))
          .integer(t('integer_required'))
          .max(getHeight(values), t('value_too_high'))
          .required(t('value_required')),
        start_time: yup.string().required(t('value_required')),
        duration: yup
          .number()
          .typeError(t('numeric_value_required'))
          .integer(t('integer_required'))
          .min(5, t('value_too_low'))
          .max(180, t('value_too_high'))
          .required(t('value_required')),
        mass: yup
          .number()
          .typeError(t('numeric_value_required'))
          .integer(t('integer_required'))
          .min(5, t('value_too_low'))
          .max(getMaxMass(values.flight_subcategory), t('value_too_high'))
          .required(t('value_required')),
        flight_type: yup.string().required(t('value_required')),
        flight_category: yup.string().required(t('value_required')),
      })
    })
  }

  const initialValues = {
    range: props.flightData.range,
    max_height: props.flightData.max_height,
    start_time: props.flightData.start_time,
    duration: props.flightData.duration,
    mass: props.flightData.mass,
    flight_type: props.flightData.flight_type,
    flight_category: props.flightData.flight_category,
    flight_subcategory: props.flightData.flight_subcategory,
    maxHeightAllowed: props.flightData.maxHeightAllowed,
  }

  const onSubmit = (values: any) => {
    values.maxHeightAllowed = getHeight(values)
    props.commitFlightData(values)
    LocalStorageTTL.set('checkInData', values, 3600000 * 24 * 28)
    toast.success(t('flight_data_saved'))
    handleClose()
  }

  const handleReset = () => {
    handleClose()
    formikRef.current && formikRef.current.resetForm()
  }

  const mapFlightTypeCategories = {
    VLOS: {
      OPEN: {
        subcategory: ['A1', 'A2', 'A3'],
        range: {
          min: 0,
          max: 600,
        },
      },
      SPECIFIC: {
        subcategory: [
          'NSTS_01',
          'NSTS_02',
          'NSTS_03',
          'NSTS_04',
          'NSTS_05',
          'NSTS_06',
          'NSTS_07',
          'NSTS_08',
          'Zezwolenie ULC',
          'Klub modelarski',
          'LUC',
        ],
        range: {
          min: 0,
          max: 600,
        },
      },
      CERTIFIED: {
        subcategory: [''],
        range: {
          min: 0,
          max: 600,
        },
      },
    },

    BVLOS: {
      SPECIFIC: {
        subcategory: ['NSTS_05', 'NSTS_06', 'NSTS_07', 'NSTS_08', 'Zezwolenie ULC', 'LUC'],
        range: {
          min: 0,
          max: 2000,
        },
      },
      CERTIFIED: {
        subcategory: [''],
        range: {
          min: 0,
          max: 10000,
        },
      },
    },
  }

  const getHeight = (values: any) => {
    if (values) {
      if (
        values.flight_category === 'OPEN' ||
        (values.flight_category === 'SPECIFIC' && values.flight_subcategory?.includes('NSTS'))
      )
        return 120
      else if (
        (values.flight_type === 'VLOS' && values.flight_category === 'CERTIFIED') ||
        (values.flight_type === 'VLOS' &&
          values.flight_category === 'SPECIFIC' &&
          (values.flight_subcategory === 'Zezwolenie ULC' ||
            values.flight_subcategory === 'Klub modelarski' ||
            values.flight_subcategory === 'LUC'))
      )
        return 300
      else return 10000
    } else return 120
  }

  const getMaxMass = (flight_subcategory: any) => {
    if (flight_subcategory === 'A1') return 900
    else if (flight_subcategory === 'A2') return 2000
    else if (flight_subcategory === 'A3') return 25000
    else if (flight_subcategory === 'NSTS_01' || flight_subcategory === 'NSTS_05') return 4000
    else if (
      flight_subcategory === 'NSTS_02' ||
      flight_subcategory === 'NSTS_03' ||
      flight_subcategory === 'NSTS_04' ||
      flight_subcategory === 'NSTS_06' ||
      flight_subcategory === 'NSTS_07' ||
      flight_subcategory === 'NSTS_08'
    )
      return 25000
    else if (
      flight_subcategory === 'Zezwolenie ULC' ||
      flight_subcategory === 'Klub modelarski' ||
      flight_subcategory === 'LUC'
    )
      return 150000
    else return 150000
  }

  const getRange = (values: any) => {
    if (values.flight_type === 'BVLOS' && values.flight_subcategory?.includes('NSTS')) return 2000
    else if (values.flight_type === 'BVLOS') return 10000
    else return 600
  }

  const getHeightMarks = (formik: { values: any }) => {
    const maxHeight = getHeight(formik.values)
    let marksHeight = []
    let stepHeight = 10
    let markSteps = [30]
    switch (maxHeight) {
      case 120:
        stepHeight = 10
        markSteps = [0, 30, 50, 100, maxHeight]
        marksHeight = createMarksArray({ steps: markSteps })
        break
      case 300:
        stepHeight = 10
        markSteps = [0, 30, 50, 100, 200, maxHeight]
        marksHeight = createMarksArray({ steps: markSteps, shouldNotDisplayAllLabels })
        break
      default:
        stepHeight = 1000
        markSteps = [0, 2000, 4000, 6000, 8000, maxHeight]
        marksHeight = createMarksArray({ steps: markSteps, shouldNotDisplayAllLabels })
        break
    }
    return { marksHeight: marksHeight, stepHeight: stepHeight }
  }

  const getMassMarks = (formik: { values: { flight_subcategory: string } }) => {
    const maxMass = getMaxMass(formik.values.flight_subcategory)
    let marksMass: any = []
    let stepMass = 1
    let markSteps = [100]
    switch (maxMass) {
      case 900:
        stepMass = 1
        markSteps = [0, 150, 300, 450, 600, 750, maxMass]
        marksMass = createMarksArray({ steps: markSteps })
        break
      case 2000:
        stepMass = 1
        markSteps = [0, 500, 1000, 1500, maxMass]
        marksMass = createMarksArray({ steps: markSteps })
        break
      case 4000:
        stepMass = 1
        markSteps = [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, maxMass]
        marksMass = createMarksArray({ steps: markSteps, shouldNotDisplayAllLabels })
        break
      case 25000:
        stepMass = 1
        markSteps = [0, 5000, 10000, 15000, 20000, maxMass]
        marksMass = createMarksArray({ steps: markSteps, shouldNotDisplayAllLabels })
        break
      case 150000:
        stepMass = 1
        markSteps = [0, 25000, 50000, 75000, 100000, 125000, maxMass]
        marksMass = createMarksArray({ steps: markSteps, shouldNotDisplayAllLabels })
        break
    }
    return { marksMass, stepMass }
  }

  const changeSliderRange = (event: any, value: number | number[], formikProps: FormikProps<{ name: string }>) => {
    formikProps.setFieldValue(event.target.name, event.target.value)
  }

  const handleNumberInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formikProps: FormikProps<{ name: string }>
  ) => {
    formikProps.setFieldValue(event.target.id, parseInt(event.target.value) || '')
  }

  const handleBlur = (event: any, formikProps: any, min: number, max: number) => {
    if (event.target.value < min) {
      formikProps.setFieldValue(event.target.id, min)
    } else if (event.target.value > max) {
      formikProps.setFieldValue(event.target.id, max)
    }
  }

  return (
    <Dialog open={props.open} onClose={() => handleReset()}>
      <DialogTitle>{t('enter_flight_data')}</DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography id="input-slider" gutterBottom>
                    {t('max_height')}
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={4}>
                      <TextField
                        value={props.values.max_height}
                        size="small"
                        variant="standard"
                        key="max_height"
                        id="max_height"
                        name="max_height"
                        onChange={(event) => {
                          handleNumberInputChange(event, props)
                        }}
                        error={props.touched?.max_height && !!props.errors?.max_height}
                        helperText={
                          props.touched?.max_height && props.errors?.max_height && String(props.errors.max_height)
                        }
                        onBlur={(event) => {
                          handleBlur(event, props, 0, getHeight(props.values))
                        }}
                        inputProps={{
                          min: 0,
                          max: getHeight(props.values),
                          type: 'tel',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} className={clsx(isMobile && 'slider-extra-padding')}>
                      <Slider
                        id="max_height"
                        name="max_height"
                        defaultValue={10}
                        valueLabelDisplay="auto"
                        step={getHeightMarks(props).stepHeight}
                        marks={getHeightMarks(props).marksHeight}
                        min={0}
                        max={getHeight(props.values)}
                        value={props.values.max_height || 0}
                        onChange={(event, value) => {
                          changeSliderRange(event, value, props)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography id="input-slider" gutterBottom>
                    {t('mass')}
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={4}>
                      <TextField
                        value={props.values.mass}
                        size="small"
                        variant="standard"
                        key="mass"
                        id="mass"
                        name="mass"
                        onChange={(event) => {
                          handleNumberInputChange(event, props)
                        }}
                        onBlur={(event) => {
                          handleBlur(event, props, 0, getMaxMass(props.values.flight_subcategory))
                        }}
                        error={props.touched?.mass && !!props.errors?.mass}
                        helperText={props.touched?.mass && props.errors?.mass && String(props.errors.mass)}
                        inputProps={{
                          min: 0,
                          max: getMaxMass(props.values.flight_subcategory),
                          type: 'tel',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} className={clsx(isMobile && 'slider-extra-padding')}>
                      <Slider
                        id="mass"
                        name="mass"
                        step={getMassMarks(props).stepMass}
                        marks={getMassMarks(props).marksMass}
                        min={0}
                        max={getMaxMass(props.values.flight_subcategory)}
                        onChange={(event, value) => {
                          changeSliderRange(event, value, props)
                        }}
                        value={props.values.mass || 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className="singleItem" xs={12} sm={12}>
                <InputLabel id="flight_type">{t('flight_type')}</InputLabel>
                <Select
                  margin="dense"
                  labelId="flight_type"
                  label={t('flight_type')}
                  type="string"
                  fullWidth
                  variant="standard"
                  value={props.values.flight_type}
                  onChange={(event) => {
                    props.setFieldValue('flight_type', event.target.value)
                    props.setFieldValue(
                      'flight_category',
                      //@ts-ignore
                      Object.keys(mapFlightTypeCategories[event.target.value])[0]
                    )
                    props.setFieldValue(
                      'flight_subcategory',
                      //@ts-ignore

                      mapFlightTypeCategories[event.target.value][
                        Object?.keys(
                          //@ts-ignore
                          mapFlightTypeCategories[event.target.value]
                        )[0]
                      ]?.subcategory[0]
                    )
                  }}>
                  <MenuItem value={'VLOS'}>VLOS</MenuItem>
                  <MenuItem value={'BVLOS'}>BVLOS</MenuItem>
                </Select>
              </Grid>
              <Grid item className="singleItem" xs={12} sm={12}>
                <InputLabel id="flight_category">{t('flight_category')}</InputLabel>
                <Select
                  margin="dense"
                  labelId="flight_category"
                  label={t('flight_category')}
                  type="string"
                  fullWidth
                  variant="standard"
                  value={props.values.flight_category}
                  onChange={(event) => {
                    props.setFieldValue('flight_category', event.target.value)
                    props.setFieldValue(
                      'flight_subcategory',
                      //@ts-ignore
                      mapFlightTypeCategories[props.values.flight_type][event.target.value]?.subcategory[0]
                    )
                  }}>
                  {
                    // @ts-ignore
                    Object.keys(mapFlightTypeCategories[props.values.flight_type]).map(
                      (item: string, index: number) => (
                        //@ts-ignore
                        <MenuItem key={`${item}-${index}`} value={item}>
                          {t(item)}
                        </MenuItem>
                      )
                    )
                  }
                </Select>
              </Grid>
              <Grid item className="singleItem" xs={12} sm={12}>
                <InputLabel id="flight_subcategory">{t('flight_subcategory')}</InputLabel>
                <Select
                  disabled={
                    // @ts-ignore
                    // eslint-disable-next-line
                    !mapFlightTypeCategories[props.values.flight_type][props.values.flight_category]?.subcategory.length
                  }
                  margin="dense"
                  labelId="flight_subcategory"
                  label={t('flight_subcategory')}
                  type="string"
                  fullWidth
                  variant="standard"
                  value={props.values.flight_subcategory}
                  onChange={(event) => props.setFieldValue('flight_subcategory', event.target.value)}>
                  {
                    // @ts-ignore
                    mapFlightTypeCategories[props.values.flight_type][props.values.flight_category]?.subcategory.map(
                      (item: any, index: number) => (
                        <MenuItem key={`${item}-${index}`} value={item}>
                          {item.replace('_', '-')}
                        </MenuItem>
                      )
                    )
                  }
                </Select>
              </Grid>

              <Grid item className="singleItem" xs={12} sm={12} justify-content="center">
                <Stack direction="row" justifyContent="right" spacing={2}>
                  <Button variant="outlined" className="button-outlined-color" onClick={() => handleReset()}>
                    {t('cancel')}
                  </Button>
                  <Button variant="contained" className="button-contained-color" type="submit">
                    {t('save')}
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
