import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'

export interface loaderState {
  value: boolean
  counter: number
}

const initialState: loaderState = {
  value: false,
  counter: 0,
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      const valueToAdd = action.payload ? 1 : -1
      const currentCounter = state.counter + valueToAdd < 0 ? 0 : state.counter + valueToAdd

      state.counter = currentCounter
      state.value = currentCounter > 0
    },
  },
})

export const { setLoader } = loaderSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLoader = (state: RootState) => state.loader.value

export default loaderSlice.reducer
