import { FC } from 'react'
import { feetsToMeters } from '../../utilities/functions'

interface propsInterface {
  data: any
}

const Notam: FC<propsInterface> = ({ data }) => {
  const dataBformatted = data.b.replace(/\D/g, '').substring(2, 12) //remove characters other than digits, trim
  const dataCformatted = data.c.replace(/\D/g, '').substring(2, 12)
  return (
    <pre className={'notam-panel '}>
      {data.series} <br />
      Q){' '}
      {data.q &&
        `${data.q.fir}/${data.q.code}/${data.q.traffic}/${data.q.purpose}/${data.q.scope}/${data.q.lower}/${data.q.upper}/${data.q.coords}`}
      <br />
      A) {data.a} B) {dataBformatted} C) {dataCformatted} <br />
      E) {data.e}
      <br />
      F){feetsToMeters(data.f)}m / {data.f}ft G) {feetsToMeters(data.g)}m / {data.g}ft)
      <br />
    </pre>
  )
}

export default Notam
