import { FC, useState } from 'react'
import * as yup from 'yup'
import { modalPropInterface } from '../../utilities/modalInterfaces'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store/hooks'
import './ApiCreateAccount.scss'
import {
  DialogContent,
  Checkbox,
  TextField,
  Grid,
  Button,
  Stack,
  FormGroup,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  IconButton,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axiosDroneMapClient from '../../../apis/AxiosDroneMapClient'
import { openUniversalModal } from '../../../store/slices/universalModalSlice/universalModalSlice'

const ApiCreateAccount: FC<modalPropInterface> = ({ closeModal, id, uniqueProps }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    openApiTermsOfService = (() => console.warn('openApiTermsOfService is not provided'))
  } = uniqueProps || {}
  const [wasSubmited, setWasSubmited] = useState(false)
  const [requestErrors, setRequestErrors] = useState<Array<String>>([])

  const validationSchema = yup.object({
    first_name: yup
      .string()
      .matches(new RegExp('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{2,40}$'), t('fe_first_name_matches'))
      .max(40)
      .required(t('fe_first_name_required')),
    last_name: yup
      .string()
      .matches(new RegExp('^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{2,40}$'), t('fe_last_name_matches'))
      .max(40)
      .required(t('fe_last_name_required')),
    email: yup
      .string()
      .email(t('fe_email_not_valid'))
      .required(t('fe_email_required')),
    password: yup
      .string()
      .min(8, t('fe_password_min_length'))
      .required(t('fe_password_required')),
    accept_terms_of_service: yup
      .boolean()
      .oneOf([true], t('accept_terms_of_service_required')),
  })

  const handleClose = () => {
    closeModal(id)
  }

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      accept_terms_of_service: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      setRequestErrors([])
      axiosDroneMapClient.userCreate(values)
        .then((response: any) => {
          dispatch(
            openUniversalModal({
              isOpenModal: true,
              isCloseButtonVisible: false,
              modalType: 'api_key',
              title: t('api_keys_modal_title'),
              disableBackdropClick: true,
              uniqueProps: {
                apiKey: response['x-api-key'],
              },
            })
          )
          handleClose()
        })
        .catch((error: any) => {
          const errorMsg = error.message === 'ERROR_USER_EXISTS' ? t('user_already_exists') : t('connection_error')
          setRequestErrors((prev) => {
            return [...prev, errorMsg]
          })
        })
    },
  })

  // can be custom hook if needed in more places
  const translationWithClickablePartToJSX = (translation: string, onClick: () => any) => {
    return t(translation)
      .split(/(#)/)
      .map((str, i) => {
        if (str !== '#')
          return <span key={i}>{str}</span>
        return <button
          type="button"
          key={i}
          className="no-decoration-link-button"
          onClick={onClick}
        >
          {t(`${translation}_#`)}
        </button>
      })
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <DialogContent className="ApiCreateAccount">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              name="first_name"
              label={t('first_name')}
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={formik.touched?.first_name && !!formik.errors?.first_name}
              helperText={formik.touched?.first_name && formik.errors?.first_name && String(formik.errors.first_name)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              name="last_name"
              label={t('last_name')}
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched?.last_name && !!formik.errors?.last_name}
              helperText={formik.touched?.last_name && formik.errors?.last_name && String(formik.errors.last_name)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              name="email"
              label={t('email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched?.email && !!formik.errors?.email}
              helperText={formik.touched?.email && formik.errors?.email && String(formik.errors.email)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              value={formik.values.password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formik.touched?.password && !!formik.errors?.password}
              helperText={formik.touched?.password && formik.errors?.password && String(formik.errors.password)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormGroup>
              <div className="input-container">
                <FormControlLabel
                  style={{ userSelect: 'none' }}
                  control={
                    <Checkbox
                      id="accept_terms_of_service"
                      name="accept_terms_of_service"
                      checked={formik.values.accept_terms_of_service}
                      onChange={formik.handleChange}
                    />
                  }
                  label={translationWithClickablePartToJSX('accept_terms_of_service', openApiTermsOfService)}
                />
              </div>
              {wasSubmited && formik.errors?.accept_terms_of_service &&
                <FormLabel className="ApiCreateAccount__warningText">
                  {String(formik.errors.accept_terms_of_service)}
                </FormLabel>
              }
            </FormGroup>
          </Grid>
          {wasSubmited && requestErrors.map((error, index) => (
            <Grid item xs={12} sm={12} key={index}>
              <FormLabel className="ApiCreateAccount__warningText">
                {String(error)}
              </FormLabel>
            </Grid>
          ))}

          <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" className="button-outlined" onClick={handleClose}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                className="button-contained"
                onClick={() => setWasSubmited(true)}
                type="submit"
              >
                {t('save')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  )
}

export default ApiCreateAccount
