import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import loaderReducer from './slices/loaderSlice/loaderSlice'
import cleanReducer from './slices/cleanSlice/cleanSlice'
import universalModalReducer from './slices/universalModalSlice/universalModalSlice'
import dronesReducer from './slices/dronesSlice/dronesSlice'

export const store = configureStore({
  reducer: {
    drones: dronesReducer,
    loader: loaderReducer,
    clean: cleanReducer,
    universalModal: universalModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
