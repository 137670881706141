import { isMobile } from 'react-device-detect'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxHeight: 'calc(100% - 20px)',
          margin: 20,
          root: {
            overflowY: isMobile ? 'auto' : 'unset',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: isMobile ? 'unset' : 'auto',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: isMobile ? '10px 45px 10px 24px' : '16px 45px 16px 24px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: isMobile
        ? {
            markLabel: {
              fontSize: ' 0.675rem',
            },
          }
        : {},
    },
  },
})
