import { createRoot } from 'react-dom/client'
import './index.scss'
import App from './App'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { store } from './store'
import UniversalModal from './modals'
import { theme } from './utilities/theme'
import './i18n/config'
import { BrowserRouter } from 'react-router-dom'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <UniversalModal />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
)
