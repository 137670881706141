import { FC } from 'react'
import IconButton from '@mui/material/IconButton'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import ExploreIcon from '@mui/icons-material/Explore'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import clsx from 'clsx'
import './MapOptionsPanel.scss'

interface propsInterface {
  setCheckZone: Function
  geolocateMe: Function
  setOpenKml: Function
  setOpenMenu: Function
  setHeadingNorth: Function
  checkZone: boolean
  isCollapsedSearch: boolean
  setIsCollapsedSearch: Function
  setOpenApiMenu: Function
}

const CenteredTextIcon = (props: any) => {
  const { text, fontSize } = props
  return (
    <svg>
      <text
        x="50%"
        y="50%"
        fontFamily="sans-serif"
        fontSize={fontSize}
        fontWeight="900"
        textAnchor="middle"
        dominantBaseline="middle"
        fill="currentColor"
      >
        {text}
      </text>
    </svg>
  );
};

const MapOptionsPanel: FC<propsInterface> = ({
  setCheckZone,
  geolocateMe,
  setOpenKml,
  setOpenMenu,
  setHeadingNorth,
  checkZone,
  isCollapsedSearch,
  setIsCollapsedSearch,
  setOpenApiMenu,
}) => {
  return (
    <div className="map-options-container">
      <div className="btn-icons">
        <div id="geocoder-container" className={clsx(isCollapsedSearch && 'collapsed-input', 'search-input')} />
        <IconButton className="btn-icon" onClick={() => setIsCollapsedSearch(!isCollapsedSearch)}>
          <SearchIcon fontSize="inherit" />
        </IconButton>
        <IconButton className={'btn-icon' + (checkZone ? 'active' : '')} onClick={() => setCheckZone(true)}>
          <NotListedLocationIcon />
        </IconButton>
        <IconButton className="btn-icon" onClick={() => geolocateMe(true)}>
          <MyLocationIcon />
        </IconButton>
        <IconButton className="btn-icon" onClick={() => setHeadingNorth()}>
          <ExploreIcon />
        </IconButton>
        <IconButton className="btn-icon" onClick={() => setOpenKml(true)}>
          <FileDownloadIcon />
        </IconButton>
        <IconButton className="btn-icon hidden" onClick={() => setOpenApiMenu(true)}>
          <CenteredTextIcon text="API" fontSize="14" />
        </IconButton>
        <IconButton className="btn-icon" onClick={() => setOpenMenu(true)}>
          <MenuIcon />
        </IconButton>
      </div>
    </div>
  )
}
export default MapOptionsPanel
