import { FC } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import { useAppDispatch } from '../../../store/hooks'
import { openUniversalModal } from '../../../store/slices/universalModalSlice/universalModalSlice'
import { useTranslation } from 'react-i18next'
import { modalPropInterface } from '../../utilities/modalInterfaces'
import LanguageSelect from '../../../components/LanguageSelect/LanguageSelect'
import axiosDroneMapClient from '../../../apis/AxiosDroneMapClient'

const API_SWAGGER_URL = `${axiosDroneMapClient.baseUrl()}/swagger-ui/index.html`;

const ApiMenu: FC<modalPropInterface> = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const openApiTermsOfService = () => {
    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: true,
        modalType: 'custom_manual_pdf',
        title: t('api_terms_of_service'),
        uniqueProps: {
          filePath: `./pansa-api-terms-of-service-${i18n.language}.pdf`,
        },
      })
    )
  }

  const openRegister = () => {
    dispatch(
      openUniversalModal({
        isOpenModal: true,
        isCloseButtonVisible: true,
        modalType: 'api_create_account',
        title: t('api_register'),
        uniqueProps: {
          openApiTermsOfService,
        },
      })
    )
  }

  return (
    <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button className="button-menu-shape button-contained-color" onClick={openApiTermsOfService}>
        {t('api_terms_of_service')}
      </Button>
      <a
        href={API_SWAGGER_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="button-menu-shape button-contained-color">
          {t('api_swagger')}
        </Button>
      </a>
      <Button className="button-menu-shape button-contained-color" onClick={openRegister}>
        {t('api_register')}
      </Button>
      <LanguageSelect />
    </DialogContent >
  )
}

export default ApiMenu
