import axios, { AxiosInstance } from 'axios'

const elevationApiBaseUrl = process.env.REACT_APP_ELEVATION_SERVICE_URL

class ElevationAPI {
  axios: AxiosInstance
  constructor() {
    this.axios = axios.create({
      timeout: 3000,
    })
  }

  async getElevation(lat: number, lng: number): Promise<number | null> {
    return this.axios
      .get(elevationApiBaseUrl + `/srtm?lat=${lat}&lon=${lng}`)
      .then(function (res) {
        const hgtElevation = res?.data?.hgtElevation;
        if (typeof hgtElevation !== 'number') {
          throw new Error('Cant get elevation')
        }
        return hgtElevation
      })
      .catch((e: any) => {
        if (e?.response?.data?.code === 'ENOENT')
          return null;
        throw e;
      })
  }
}

const elevationApi = new ElevationAPI()

export default elevationApi
