export default class LocalStorageTTL {
  static set(key: string, value: any, ttlMs: number) {
    const item = { value }

    if (typeof ttlMs === 'number') {
      //@ts-ignore
      item.ttl = new Date().getTime() + ttlMs
    }

    localStorage.setItem(key, JSON.stringify(item))
  }

  static get(key: string) {
    const item = localStorage.getItem(key)
    if (item) {
      const itemParsed = JSON.parse(item)

      if (itemParsed.ttl) {
        const now = new Date().getTime()

        if (now >= itemParsed.ttl) {
          localStorage.removeItem(key)
          return undefined
        }

        return itemParsed.value
      }

      return itemParsed.value
    }
    return undefined
  }

  static remove(key: string) {
    localStorage.removeItem(key)
  }
}
