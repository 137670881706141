import { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { selectClean, setClean } from '../../../store/slices/cleanSlice/cleanSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useTranslation } from 'react-i18next'


const CleanModal: FC = () => {
  const cleanOpen = useAppSelector(selectClean)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleClose = () => {
    dispatch(setClean(false))
  }

  const handleReloadPage = () => {
    setTimeout(() => {
      window.location.reload()
    })
  }

  return (
    <Dialog open={cleanOpen} onClose={handleClose}>
      <DialogTitle>{t('data_error')}</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>{t('error_occured_option_select')}</Typography>
        <Button onClick={handleReloadPage}>{t('refresh_app')}</Button>
      </DialogContent>
    </Dialog>
  )
}

export default CleanModal
