import { FC, useEffect, useRef, useState } from 'react'
import './ZonesModal.scss'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { getZonesOrder } from '../../../utilities/functions'
import Accordions from '../../map/Accordions'
import Button from '@material-ui/core/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { toast } from 'react-toastify'
import { isEmpty, isEqual } from 'lodash'
import { featuresInterface, zonesPhoneNumbersInterface } from '../../../utilities/sharedInterfacesAndTypes'
import { zonesPhoneNumbers } from '../../../utilities/constants'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import * as yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
interface propsInterface {
  open: boolean
  bvlos: boolean
  isLoading: boolean
  max_height: number
  mass: number
  lat: number
  max_heightAMSL: number | undefined
  lng: string
  mode: string
  features: { zones: Array<featuresInterface>; requirements: Array<string> }
  setOpenZones: Function
}

const ZonesModal: FC<propsInterface> = ({
  open,
  bvlos,
  isLoading,
  max_height,
  mass,
  lat,
  max_heightAMSL,
  lng,
  mode,
  features,
  setOpenZones,
}) => {
  const [isopen, setIsOpen] = useState(open)
  const [restrictions, setRestrictions] = useState<Array<boolean>>([false, false])
  const [allNumbers, setAllNumbers] = useState<Array<zonesPhoneNumbersInterface>>([])
  const prevFeatures = useRef<Array<{ properties: { name: string } }>>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (!features?.zones || !features?.requirements)
      return

    if (!isEqual(features.zones, prevFeatures.current)) {
      prevFeatures.current = features.zones
      const numbers: Array<zonesPhoneNumbersInterface> = []
      const featureNames = features.zones.map((feature) => feature.properties.name)

      zonesPhoneNumbers.forEach((zone) => {
        featureNames.forEach((feature) => {
          if (feature.includes(zone.twr) && !numbers.includes(zone)) {
            numbers.push(zone)
          }
        })
      })

      if (!isEmpty(numbers)) {
        setAllNumbers(numbers)
      }
      setRestrictions([
        !features.requirements.includes('REQUIRED_FLIGHT_PLAN'),
        !features.requirements.includes('REQUIRED_PHONE_COORDINATION'),
      ])
    }
  }, [bvlos, features.requirements, features.zones, mass, max_height, restrictions])

  const handleClose = () => {
    setOpenZones(false)
    setIsOpen(false)
  }
  addEventListener('popstate', () => {
    handleClose()
  })

  const properDate = 'YYMMDD'
  const hourFormat = 'HHmm'

  const nowUTC = moment.utc() //UTC is needed to validate date and time in the field

  const nowDate = nowUTC.format(properDate)
  const nowHour = nowUTC.format(hourFormat)

  const validationSchema = () => {
    return yup.lazy(() => {
      return yup.object({
        mission_plan_consent_no: yup
          .mixed()
          .test('mission_plan_consent_no', t('mission_plan_matches'), (val, ctx) => {
            val
            ctx
            const regexp = new RegExp('UTM-[0-9]{6}-[0-9]{4}-[a-zA-Z0-9]{4}$')
            return regexp.test(val)
          })
          .test('mission_plan_consent_no', t('enter_proper_date'), (val, ctx) => {
            val
            ctx
            const regexp = new RegExp(`UTM-${nowDate}-[0-9]{4}-[a-zA-Z0-9]{4}$`)
            return regexp.test(val)
          })
          .test('mission_plan_consent_no', t('mission_plan_hour_input'), (val, ctx) => {
            val
            ctx
            const regexp = new RegExp(`UTM-${nowDate}-([0-1]?[0-9]|2[0-3])[0-5][0-9]-[a-zA-Z0-9]{4}$`)
            return regexp.test(val)
          })
          .test('mission_plan_consent_no', t('mission_plan_hour_input'), (val, ctx) => {
            ctx
            const hourTyped = val.substring(11, 15)
            return hourTyped < nowHour ? true : false
          }),
      })
    })
  }

  const formik = useFormik({
    initialValues: {
      mission_plan_consent_no: `UTM-${nowDate}-`,
    },
    validationSchema: validationSchema,
    // eslint-disable-next-line
    onSubmit: async () => { },
  })

  const mission_plan_consent_no_value = formik.values.mission_plan_consent_no
  function strWithoutEndingDashes(s: string): string {
    if (isEmpty(s) || s.at(s.length - 1) !== '-') {
      return s
    }
    let dashIdx = s.length - 1
    while (dashIdx > 0 && s.at(dashIdx - 1) === '-') {
      dashIdx -= 1
    }
    return s.slice(0, dashIdx)
  }

  useEffect(() => {
    if (!mission_plan_consent_no_value) {
      return
    }
    if (mission_plan_consent_no_value.at(mission_plan_consent_no_value.length - 1) === '-') {
      const newValue = strWithoutEndingDashes(mission_plan_consent_no_value)
      formik.setFieldValue('mission_plan_consent_no', newValue)
    } else if (
      (mission_plan_consent_no_value.length === 4 ||
        mission_plan_consent_no_value.length === 11 ||
        mission_plan_consent_no_value.length === 16) &&
      mission_plan_consent_no_value.at(mission_plan_consent_no_value.length - 2) !== '-'
    ) {
      const newValue =
        mission_plan_consent_no_value.slice(0, mission_plan_consent_no_value.length - 1) +
        '-' +
        mission_plan_consent_no_value.at(mission_plan_consent_no_value.length - 1)
      formik.setFieldValue('mission_plan_consent_no', newValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission_plan_consent_no_value])

  if (!features.requirements || !features.zones) {
    return null
  }

  return (
    <Dialog open={isopen} onClose={handleClose}>
      <DialogTitle>
        {t('geographic_zones')}{' '}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute!important',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Accordions
          showRequirements={[
            features.requirements.includes('REQUIRED_FLIGHT_PLAN'),
            features.requirements.includes('REQUIRED_PHONE_COORDINATION'),
          ]}
          restrictions={restrictions}
          setRestrictions={setRestrictions}
          mass={mass}
          bvlos={bvlos}
          lat={lat}
          max_height={max_height}
          lng={lng}
          accordions={getZonesOrder(features.zones)}
          max_heightAMSL={max_heightAMSL ? Math.ceil(max_heightAMSL) : max_heightAMSL}
          allNumbers={allNumbers}
          isCheckInMode={mode === 'checkIn'}
          notificationFn={toast.info}
          notificationErrorFn={toast.error}
        />
        {mode === 'checkIn' && features.requirements.includes('REQUIRED_FLIGHT_PLAN') && (
          <div style={{ marginTop: '10px' }}>
            <form id="zgoda" onSubmit={formik.handleSubmit}>
              <TextField
                inputProps={{ maxLength: 20 }}
                style={{ marginTop: '10px' }}
                fullWidth
                id="mission_plan_consent_no"
                label={t('mission_plan_input_label')}
                placeholder="UTM-YYMMDD-HHmm-XXXX"
                value={formik.values.mission_plan_consent_no}
                onChange={formik.handleChange}
                error={formik.touched?.mission_plan_consent_no && !!formik.errors?.mission_plan_consent_no}
                helperText={
                  formik.touched?.mission_plan_consent_no &&
                  formik.errors?.mission_plan_consent_no &&
                  String(formik.errors.mission_plan_consent_no)
                }
              />
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Button color="primary" variant="outlined" onClick={handleClose}>
                  {t('cancel')}
                </Button>
                <LoadingButton
                  loading={isLoading}
                  loadingPosition="end"
                  color="primary"
                  variant="contained"
                  type="submit">
                  {t('acceptance')}
                </LoadingButton>
              </div>
            </form>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ZonesModal
