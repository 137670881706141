export const conditions_content_en = `            <p>
              Airspace structures are dynamic. They can change in time and place. New zones,
               can appear at any place and time. It is the responsibility of the Drone pilot 
               to become aware of the current restrictions.
            </p>
            <br />
            <p>
              The map is for informational purposes and does not relieve the user of the obligation 
              to read all available NOTAM/AUP/UUP dispatches. For the latest information, call
               AMC Poland +48 22 574 5733-35
            </p>
            <br />
            <p>
              Obtaining permission does not release you from the obligation to comply with air 
              traffic rules. The entire flight must be conducted under applicable regulations.
            </p>
            <br />
            <p>
              The flight area marked in the mission is not a separate area for your operation!
               There is always the possibility of the appearance of another aircraft, including 
               manned ones, which must be given absolute priority.
            </p>
            <br />
            <p>
              Be aware of the possibility of another aircraft in the DRA-I geographic zone, which
              is designated for BVLOS flights.
            </p>
            <br />
            <p>
              DRA-I geographic zones indicating BVLOS flights are published only for BSP pilots
               and are not seen by manned aviation!
            </p>
            <br />
            <ul>
              <li>
                By accepting this window, you confirm that you are responsible for maintaining the
                required distances from people, gatherings of people, and buildings during the mission.
                As specified in the provisions of Commission Implementing Regulation (EU) 2019/947
                of May 24, 2019, and Guideline No. 7 of the President of the Civil Aviation Authority,
                dated June 9, 2021, on how to carry out operations using unmanned aircraft systems
                in connection with the entry into force of the provisions of Commission Implementing                    Regulation (EU) No. 2019/947 of May 24, 2019, on regulations and procedures for the
                operation of unmanned aircraft.
              </li>
              <li>
                By accepting this window, you confirm that you have acquainted yourself with all
                 restrictions at the site of the planned BSP operation and the available NOTAM/AUP/UUP 
                 dispatches.
              </li>
            </ul>`

    
            
