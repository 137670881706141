import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import Typography from '@mui/material/Typography'
import { feetsToMeters } from '../../utilities/functions'
import './ActivationsTable.scss'

interface propsInterface {
  rows: Array<any>
}

const ActivationsTable: FC<propsInterface> = ({ rows }) => {
  return (
    <div>
      <Typography>Sprawdź aktywność w poniższej tabeli (czas lokalny):</Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell id="mobile" align="left" style={{ fontWeight: 'bold' }}>
                Nazwa
              </TableCell>
              <TableCell id="mobile" align="left" style={{ fontWeight: 'bold' }}>
                Start
              </TableCell>
              <TableCell id="mobile" align="left" style={{ fontWeight: 'bold' }}>
                Stop
              </TableCell>
              <TableCell id="mobile" align="left" style={{ fontWeight: 'bold' }}>
                Min
              </TableCell>
              <TableCell id="mobile" align="left" style={{ fontWeight: 'bold' }}>
                Max
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(
              (
                row: {
                  designator: string
                  start: string | number | Date
                  stop: string | number | Date
                  min: string | number
                  max: string | number
                },
                index: number
              ) => (
                <TableRow key={`${row.designator}-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell id="mobile" component="th" scope="row">
                    {row.designator}
                  </TableCell>
                  <TableCell id="mobile" align="left">
                    {moment(row.start).format('DD/MM/YY HH:mm')}
                  </TableCell>
                  <TableCell id="mobile" align="left">
                    {moment(row.stop).format('DD/MM/YY HH:mm')}
                  </TableCell>
                  <TableCell id="mobile" align="left">
                    {rows[index].min}ft ({feetsToMeters(rows[index].min)} m)
                  </TableCell>
                  <TableCell id="mobile" align="left">
                    {rows[index].max}ft ({feetsToMeters(rows[index].max)} m)
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ActivationsTable
