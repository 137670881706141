import axios, { AxiosError, AxiosInstance } from 'axios'
import { asyncSleep } from '../utilities/functions'

export interface IUserCreate {
  password: string
  email: string
  first_name: string
  last_name: string
}

export interface IEventCheckConflicts {
  start_time: Date,
  max_height: number,
  lon: number,
  lat: number,
  range: number,
  mass: number,
  flight_type: string,
  flight_category: string,
  flight_subcategory: string,
}

class AxiosDroneMapClient {
  axios: AxiosInstance
  isLoggedIn = false
  authorizationToken = ''
  apiKey = '4b705300-8dfa-11ee-b9d1-0242ac120002'
  MAX_TRIES = 5

  constructor() {
    this.axios = axios.create({
      timeout: 5000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    });
    this.axios.interceptors.response.use(undefined, async (error: AxiosError) => await this.retryInterceptor(error));
  }

  async retryInterceptor(error: AxiosError) {
    if (axios.isCancel(error) || !error?.config) {
      throw error;
    }
    if (this.wantToRecallRequest(error)) {
      const statusCode = Number(error?.response?.status || 500)
      if (statusCode == 401) {
        const currentHeaders = this.authorizationHeaders()
        const headers = error?.config?.headers || {}
        if (currentHeaders.Authorization === headers.Authorization) {
          this.logout();
          await this.login();
        }
      }
      await asyncSleep(500);
      const url = error?.config?.url ?? ''
      if (!url.endsWith('v1/front/login')) {
        await this.waitForLogin();
        if (error.config.headers) {
          error.config.headers.Authorization = this.authorizationHeaders().Authorization
        }
      }
      return this.axios(error.config)
    }
    throw error;
  }

  wantToRecallRequest(error: AxiosError) {
    // @ts-ignore
    error.config.prevTries = (error?.config?.prevTries || 0) + 1
    // @ts-ignore
    const { prevTries } = error.config;
    const statusCode = Number(error?.response?.status) || 500
    const retry = (statusCode >= 500 || statusCode === 404 || statusCode === 401) && prevTries < this.MAX_TRIES
    return retry
  }

  async waitForLogin() {
    while (!this.authorizationToken) {
      await asyncSleep(50)
    }
    return !!this.authorizationToken
  }

  apiKeyHeaders() {
    return { 'x-api-key': this.apiKey }
  }

  authorizationHeaders() {
    return { Authorization: this.authorizationToken ? `Bearer ${this.authorizationToken}` : '' }
  }

  baseUrl() {
    const { protocol, host } = window.location
    if (process?.env?.NODE_ENV === 'development' && process?.env?.REACT_APP_BASE_URL) {
      return process.env.REACT_APP_BASE_URL
    }
    return `${protocol}//api.${host}`
  }

  async login() {
    return this.axios({
      url: this.baseUrl() + '/v1/front/login',
      method: 'POST',
      headers: {
        ...this.apiKeyHeaders(),
      }
    }).then((res) => {
      if (res.data.access_token) {
        this.authorizationToken = String(res.data.access_token)
      }
      return this.authorizationToken
    })
  }

  logout() {
    this.authorizationToken = '';
  }

  async userCreate(props: IUserCreate) {
    return this.axios({
      url: this.baseUrl() + '/v1/user/create',
      method: 'POST',
      data: {
        ...props,
        username: props.email,
      },
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    })
      .then(function (res: any) {
        return res.data
      })
      .catch((error: any) => {
        if (error?.response?.status === 409) {
          throw new Error('ERROR_USER_EXISTS')
        }
        throw new Error('UNKNOWN_ERROR')
      })

  }

  async getTimeSlices() {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/activ/timeslices',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then(function (res) {
      return res.data?.properties
    })
  }

  async getNotams() {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/notams',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then(function (res) {
      return res.data?.properties
    })
  }

  async getAupUupControl() {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/activ/aups',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then(function (res) {
      return res.data
    })
  }

  async getWebCatActivations() {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/activ/activations',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then(function (res) {
      return res.data?.properties
    })
  }

  async getFilteredZones(centerLng: number, centerLat: number, range = 600000, maxHeight = 2895) {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/zones/filtered',
      method: 'GET',
      params: {
        lon: centerLng,
        lat: centerLat,
        range,
        max_height: maxHeight,
      },
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then((res: any) => {
      return res.data?.properties
    })
  }

  async getAllZones() {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/zones',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then((res: any) => {
      return res.data?.properties
    })
  }

  async getZoneTypes() {
    await this.waitForLogin()
    return this.axios({
      url: this.baseUrl() + '/v1/zones/types',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then(function (res) {
      return res.data?.properties
    })
  }

  async getEventCheckConflicts(props: IEventCheckConflicts): Promise<any> {
    await this.waitForLogin();
    return this.axios({
      url: this.baseUrl() + '/v1/zones/pin',
      method: 'POST',
      data: props,
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    })
      .then(res => res.data)
  }

  async getBlobFromFileUrl(fileUrl: string, contentType: string) {
    await this.waitForLogin();
    const response = await this.axios.get(this.baseUrl() + fileUrl, {
      responseType: 'blob',
      headers: {
        'Accept': contentType,
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    })
    return new Blob([response.data], { type: contentType });
  }

  async fetchDrones() {
    await this.waitForLogin();
    return this.axios({
      url: this.baseUrl() + '/v1/operations',
      method: 'GET',
      headers: {
        ...this.apiKeyHeaders(),
        ...this.authorizationHeaders(),
      }
    }).then(function (res) {
      return res.data
    })
  }
}


const axiosDroneMapClient = new AxiosDroneMapClient()

export default axiosDroneMapClient
