import 'rc-slider/assets/index.css'
import Slider from '@mui/material/Slider'
import './FlightPanel.scss'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

const FlightPanel = (props: any) => {
  const setHeightFromPanel = (maxHeight: number) => {
    props.setFlightData((prevFlightData: any) => {
      prevFlightData.max_height = maxHeight
      return { ...prevFlightData }
    })
  }
  const { i18n, t } = useTranslation()

  const flightCategoryTitle = (category: string) => {
    if (i18n.language === 'pl') {
      if (category === 'OPEN') {
        return 'Otwarta'
      }
      if (category === 'SPECIFIC') {
        return 'Szczeg.'
      }
      if (category === 'CERTIFIED') {
        return 'Cert.'
      }
    }
    if (i18n.language === 'en') {
      return t(category)
    }
  }

  return (
    <div className="flight-panel-container">
      <div className="flight-panel-background">
        <div>
          <div id="nav-control">
            <div className="mapboxgl-ctrl mapboxgl-ctrl-group"></div>
            <span className={'mark-text'}>{props.flightData.maxHeightAllowed} AGL</span>
            <Stack sx={{ height: '30vh', left: '15px', position: 'relative' }} spacing={1} direction="row">
              <Slider
                aria-label="Wysokosc"
                value={props.flightData.max_height}
                orientation="vertical"
                min={0}
                step={10}
                max={props.flightData.maxHeightAllowed}
                // defaultValue={props.flightData.height}
                onChangeCommitted={(event, value) => setHeightFromPanel(+value)}
              // onChangeCommitted={(event, value) => {
              //   props.getZonesByHeight({ layerList: null, height: value, firstRun: false, locate: false })
              // }}
              />
            </Stack>
            <span className={'mark-text'}>GND</span>
            <div className="drone-container">
              <span className="flight-max-height">{props.flightData.max_height}m</span>
              <div className="drone"></div>
            </div>
          </div>
        </div>
        <Divider variant="middle" />
        <div className={'flight-box-btuttons'}>
          <Button
            size="small"
            onClick={() => props.setOpenFlightData(true)}
            className={'btn-map'}
            variant="contained"
            style={{ fontSize: '0.7rem' }}>
            {props.flightData.mass}g
          </Button>
          <Button
            size="small"
            onClick={() => props.setOpenFlightData(true)}
            className={'btn-map'}
            variant="contained"
            style={{ fontSize: '0.7rem' }}>
            {flightCategoryTitle(props.flightData.flight_category)}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default FlightPanel
