import { FC } from 'react'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useTranslation } from 'react-i18next'
import { modalPropInterface } from '../../utilities/modalInterfaces'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Grid, IconButton, InputAdornment, TextField, Typography, } from '@mui/material'

const ApiKey: FC<modalPropInterface> = ({ id, closeModal, uniqueProps }) => {
  const { handleSubmit } = useForm()
  const { t } = useTranslation()

  const onSubmit = async () => {
    closeModal(id)
  }

  const handleCopy = (toCopy: string) => {
    navigator.clipboard.writeText(toCopy);
  };

  const Field: FC<any> = (props: any) => {
    const { label, value } = props
    return <TextField
      fullWidth={true}
      name={label.toLowerCase().replace(' ', '_')}
      label={label}
      value={value}
      disabled={true}
      sx={{
        '& .Mui-disabled': {
          color: '#1976d2', // Change text color
          WebkitTextFillColor: '#1976d2', // Override for webkit browsers
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={t('copy_to_clipboard')}
              onClick={() => handleCopy(String(uniqueProps?.apiKey))}
              edge="end"
              color="primary"
            >
              <FileCopyIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  }

  return (
    <>
      <DialogContent style={{ paddingTop: 10, minWidth: 400 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Field label="Api Key" value={uniqueProps?.apiKey} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography color="error" variant="body2">
              {t('write_down_api_keys')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>
        <Button onClick={handleSubmit(onSubmit)}>{t('accept')}</Button>
      </DialogActions>
    </>
  )
}

export default ApiKey
