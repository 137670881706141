import { useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ActivationsTable from '../../components/ActivationsTable/ActivationsTable'
import Notam from './Notam'
import Typography from '@mui/material/Typography'
import { checkIsNotFlightAllowedInSpecificZone, feetsToMeters, metersToFeet } from '../../utilities/functions'
import { RestrictionsTable } from './RestrictionsTable'
import { useTranslation } from 'react-i18next'
import './Accordions.scss'
import { FeatureAccordion } from '../../utilities/sharedInterfacesAndTypes'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))
const addDataToRows = (rows: any, notam: { f: any; g: any; zonename: any }) => {
  rows &&
    rows.forEach((element: { min: any; max: any; designator: any }) => {
      element.min = notam.f
      element.max = notam.g
      element.designator = notam.zonename
    })
  return rows
}

function ddToDms(dd: number, isLongitude: boolean, sDecimals = 3) {
  const dir = dd < 0 ? (isLongitude ? 'W' : 'S') : (isLongitude ? 'E' : 'N');

  const absDD = Math.abs(dd);
  const degrees = Math.floor(absDD);
  const minutes = Math.floor((absDD - degrees) * 60);
  const seconds = Number(((absDD - degrees - minutes / 60) * 3600).toFixed(sDecimals));

  return `${degrees}° ${minutes}' ${seconds}"${dir}`;
}

function genGoogleMapsLink(lat: number, lng: number) {
  return `https://www.google.com/maps/place/${lat},${lng}`;
}

export default function Accordions(props: any) {
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const {
    // eslint-disable-next-line
    notificationFn = console.log,
    notificationErrorFn = console.warn,
  } = props;

  const getCopyToClipboardFn = (input: string, type_info_notification: string) => {
    return (event: any) => {
      event.stopPropagation()
      navigator.clipboard.writeText(input)
        .then(() => {
          notificationFn(t('copied_to_clipboard', { what: type_info_notification }))
        })
        .catch(err => {
          notificationErrorFn(t('copy_to_clipboard_error', { error_message: err.message }));
        });
    }
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }
  const getWarningColor = (zone: any) => {
    return checkIsNotFlightAllowedInSpecificZone(zone, props.max_height) ? 'red' : 'yellow'
  }
  const getColorRestriction = (zone: any) => {
    const option = getWarningColor(zone)
    if (option === 'red') {
      return 'rgb(240 22 22 / 20%)'
    } else {
      return 'rgb(240 208 22 / 20%)'
    }
  }
  const getIconRestriction = (zone: any) => {
    const option = getWarningColor(zone)
    if (option === 'red') {
      return (
        <img
          style={{ width: 35, height: 35, marginRight: '5px', background: 'white', borderRadius: '25px' }}
          src={'/drone_red.png'}
          alt=""
        />
      )
    } else {
      return (
        <img
          style={{ width: 35, height: 35, marginRight: '5px', background: 'white', borderRadius: '25px' }}
          src={'/drone_yellow.png'}
          alt=""
        />
      )
    }
  }
  const getZoneTitle = (zone: any) => {
    if (
      zone.properties.data.type === 'CTR1KM' ||
      zone.properties.data.type === 'CTR6KM' ||
      zone.properties.data.type === 'RPA' ||
      zone.properties.data.type === 'MCTR2KM' ||
      zone.properties.data.type === 'ATZ1KM' ||
      zone.properties.data.type === 'ATZ6KM'
    ) {
      return (
        <div className="slaveZone">
          <b>↳</b> {zone.properties.name}{' '}
        </div>
      )
    } else {
      return <div className="masterZone">{zone.properties.name} </div>
    }
  }


  const filterActiveRows = (rows: any) => {
    const now = moment.utc();
    //@ts-ignore
    return rows.filter(zone => {
      const stopTime = moment.utc(zone.stop);
      return stopTime.isAfter(now);
    });
  }

  const orderedByMasterFeatures: FeatureAccordion[] = []
  props.accordions.forEach((zone: any) => {
    orderedByMasterFeatures.push(zone)
  })

  const ordering: any = {},
    sortOrder = ['CTR', 'CTR1KM', 'CTR6KM', 'RPA', 'MCTR', 'MCTR2KM', 'ATZ', 'ATZ1KM', 'ATZ6KM']
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i
  orderedByMasterFeatures.sort(function (a, b) {
    const typeA = a.properties.data.type
    const typeB = b.properties.data.type

    if (ordering[typeA] === undefined) {
      return 1
    } else if (ordering[typeB] === undefined) {
      return -1
    } else {
      return ordering[typeA] - ordering[typeB]
    }
  })

  const latDd = props.lat.toFixed(9);
  const lngDd = props.lng.toFixed(9);
  const latDms = ddToDms(props.lat, false)
  const lngDms = ddToDms(props.lng, true)
  const googleMapLink = genGoogleMapsLink(props.lat, props.lng)

  return (
    <div>
      <div className="zones-details-overview">
        <h4>{t('airspaces_to')}:</h4>
        <p>
          {t('agl_height')}: <b>{metersToFeet(props.max_height)}</b>ft (<b>{props.max_height}</b> m)
        </p>

        <p>
          {t('amsl_height')}: <b>{metersToFeet(props.max_heightAMSL)}</b>ft (<b>{props.max_heightAMSL}</b> m)
        </p>
        <h4>{t('sampler_data')}:</h4>
        {/* eslint-disable-next-line */}
        <div>
          <p className="zones-details-overview__cordinates">
            <ContentCopyIcon
              className="zones-details-overview__cordinates__copy-icon"
              onClick={getCopyToClipboardFn(`${latDms}, ${lngDms}`, t('cordinates_format_dms'))}
            />
            <b>{latDms}</b>
            <b>{lngDms}</b>
          </p>
          <p className="zones-details-overview__cordinates">
            <ContentCopyIcon
              className="zones-details-overview__cordinates__copy-icon"
              onClick={getCopyToClipboardFn(`${latDd}, ${lngDd}`, t('cordinates_format_dd'))}
            />
            <b>{latDd}</b>
            <b>{lngDd}</b>
          </p>
          <p className="zones-details-overview__cordinates">
            <ContentCopyIcon
              className="zones-details-overview__cordinates__copy-icon"
              onClick={getCopyToClipboardFn(googleMapLink, t('google_maps_link'))}
            />
            <a href={googleMapLink} target="_blank" rel="noopener noreferrer">{t('google_maps')}</a>
          </p>
        </div>
        <br></br>
        <p className="infoFrame">
          <i>{t('check_zone_requirements_activity')}</i>
        </p>

        {props.showRequirements.includes(true) && (
          <div>
            <h4>{t('limitations')}:</h4>
            <RestrictionsTable
              requirements={props.showRequirements}
              isCheckInMode={props.isCheckInMode}
              setRestrictions={props.setRestrictions}
              restrictions={props.restrictions}
              allNumbers={props.allNumbers}
            />
          </div>
        )}
      </div>

      {!!orderedByMasterFeatures.length &&
        orderedByMasterFeatures.map((zone: any, index: number) => {
          const properties = zone.properties
          return (
            <Accordion
              key={`${properties.name}-${index}`}
              expanded={expanded === properties.name}
              onChange={handleChange(properties.name)}>
              <AccordionSummary
                aria-controls={properties.name + '-content'}
                style={{ width: '100%', background: getColorRestriction(zone) }}>
                <div className="accordion-summary-main">
                  <div className="box">
                    <div className="box-title">{getZoneTitle(zone)}</div>
                    <div className="box-icon">{getIconRestriction(zone)}</div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div className="accordion-title">
                    <h4>{t('zone_description')}:</h4>
                  </div>
                  <div className={'accordion-description'}>{properties.data?.description?.[language]}</div>
                  <div className="accordion-title">
                    <h4>{t('zone_scope_amsl')}:</h4>
                  </div>
                  <p>
                    <b>{t('min')}:</b> {properties.data?.min}ft ({feetsToMeters(properties.data?.min)}m)
                  </p>
                  <p>
                    <b>{t('max')}:</b> {properties.data?.max}ft ({feetsToMeters(properties.data?.max)}m)
                  </p>
                  {properties.data.contact && (
                    <p>
                      {t('tel_contact')}: {properties.data.contact.replace(';', '')}{' '}
                    </p>
                  )}
                  {/* <div className="icon">
                    {getIconRestriction(zone)} - {getDescRestriction(zone)}
                  </div> */}
                </div>
                {!properties.data.masterZone && properties.data.notam && !!properties.data.notam[0].e && (
                  <div className="accordion-title">
                    <h4>{t('notam_telegram')}:</h4>
                  </div>
                )}
                {properties.data?.notam && !!properties.data?.notam[0].e && (
                  <div>
                    <Notam data={properties.data.notam[0]} />
                    <div className="accordion-title">
                      <h4>{t('notam_activation')}:</h4>
                    </div>
                    {properties.data.notam && !!properties.data.notam[0].notamActivationsThisDay.length && (
                      <ActivationsTable
                        rows={addDataToRows(properties.data.notam[0].notamActivationsThisDay, properties.data.notam[0])}
                      />
                    )}
                  </div>
                )}
                {!!properties.data?.activity?._aixmActivationsThisDay?.length &&
                  <ActivationsTable rows={properties.data?.activity._aixmActivationsThisDay} />
                }

                {!properties.data.masterZone && properties.activation && !!properties.activation.length && (
                  <div className="accordion-title">
                    <h4>{t('zone_activations')}:</h4>
                  </div>
                )}
                {zone.activation &&
                  !!zone.activation.length &&
                  <ActivationsTable rows={filterActiveRows(zone.activation)} />}
                {!!properties.data.masterZone && (
                  <Typography>
                    {t('zone_details')} {properties.data.masterZone.name}
                  </Typography>
                )}
                {!properties.data.activity.isActive && (
                  <Typography className="noActivityHighlighted">&#10003; {t('no_current_activity')}</Typography>
                )}
                {properties.data.activity.h24Active && (
                  <Typography className="activityHighlighted"> &#9888; {t('h24_activation')}</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )
        })}
      {!!props.accordions.length === false && (
        <b>
          <div className="infoFrame no-activty">&#10003; {t('no_zones')}</div>
        </b>
      )}
    </div>
  )
}
