import { FC, MouseEventHandler } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import { useAppDispatch } from '../../../store/hooks'
import { openUniversalModal } from '../../../store/slices/universalModalSlice/universalModalSlice'
import LanguageSelect from '../../../components/LanguageSelect/LanguageSelect'
import { useTranslation } from 'react-i18next'
import { modalPropInterface } from '../../utilities/modalInterfaces'

interface buttonInterface {
  title: string
  onClick: MouseEventHandler
}

const Menu: FC<modalPropInterface> = ({ uniqueProps }) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const manualLangPostfix = i18n.language === 'pl' ? '' : `-${i18n.language}`

  const menuButtons: Array<buttonInterface> = [
    {
      title: t('user_manual'),
      onClick: () =>
        dispatch(
          openUniversalModal({
            isOpenModal: true,
            isCloseButtonVisible: true,
            modalType: 'custom_manual_pdf',
            title: t('user_manual'),
            uniqueProps: {
              ...uniqueProps,
              filePath: `./manual${manualLangPostfix}.pdf`,
            },
          })
        ),
    },
  ]

  return (
    <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {menuButtons.map((button) => (
        <Button className="button-menu-shape button-contained-color" onClick={button.onClick} key={button.title}>
          {button.title}
        </Button>
      ))}
      <LanguageSelect />
    </DialogContent>
  )
}

export default Menu
