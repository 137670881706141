import { FC } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import SvgIcon from '@mui/material/SvgIcon'
import Checkbox from '@mui/material/Checkbox'
import { green } from '@mui/material/colors'
import { ReactComponent as Pansautm } from '../../icons/pansautm_logo.svg'
import { ReactComponent as ControlTower } from '../../icons/pazp_wieza.svg'
import { isEmpty } from 'lodash'
import { zonesPhoneNumbersInterface } from '../../utilities/sharedInterfacesAndTypes'
import { useTranslation } from 'react-i18next'

interface propsInterface {
  isCheckInMode: boolean
  setRestrictions: Function
  restrictions: Array<boolean>
  allNumbers: Array<zonesPhoneNumbersInterface>
  requirements: Array<boolean>
}

function setRowStyle(checked: boolean) {
  return { '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: checked ? green[100] : 'white' }
}

export const RestrictionsTable: FC<propsInterface> = ({
  setRestrictions,
  restrictions,
  allNumbers,
  isCheckInMode,
  requirements,
}) => {
  const { t } = useTranslation()

  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">{t('kind')}</TableCell>
          {isCheckInMode && <TableCell align="center">{t('acceptance')}</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={1} sx={setRowStyle(restrictions[0])}>
          <TableCell align="left" padding="none">
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <a target="_blank" href="https://utm.pansa.pl/#/login" rel="noreferrer">
                <SvgIcon
                  style={{ width: '60px', height: '46px' }}
                  fontSize="large"
                  component={Pansautm}
                  inheritViewBox
                />
              </a>
              <p>{t('mission_plan_req')}</p>
            </div>
          </TableCell>
          {isCheckInMode && (
            <TableCell padding="checkbox" align="center">
              <Checkbox
                onChange={() => setRestrictions([!restrictions[0], restrictions[1]])}
                checked={restrictions[0]}
              />
            </TableCell>
          )}
        </TableRow>
        {requirements[1] && (
          <TableRow key={2} sx={setRowStyle(restrictions[1])}>
            <TableCell align="left" padding="none">
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                {allNumbers[0] && (
                  <a href={`tel:${allNumbers[0].numbers[0]}`} rel="noreferrer">
                    <SvgIcon
                      style={{ width: '60px', height: '46px', paddingTop: 5 }}
                      fontSize="large"
                      component={ControlTower}
                      inheritViewBox
                    />
                  </a>
                )}
                {!allNumbers[0] && (
                  <SvgIcon
                    style={{ width: '60px', height: '46px', paddingTop: 5 }}
                    fontSize="large"
                    component={ControlTower}
                    inheritViewBox
                  />
                )}
                <p>{t('tower_contact_req')}</p>
              </div>

              {isEmpty(allNumbers) && (
                <div key={'twr'} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <p style={{ padding: '0 10px' }}>{t('telephone_info_in_zone')}</p>
                </div>
              )}
            </TableCell>
            {isCheckInMode && (
              <TableCell padding="checkbox" align="center">
                <Checkbox
                  onChange={() => setRestrictions([restrictions[0], !restrictions[1]])}
                  checked={restrictions[1]}
                />
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
