/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../index'
import axiosDroneMapClient from '../../../apis/AxiosDroneMapClient'

export interface DronePoint {
  type: 'Point',
  coordinates: [number, number]
}

export interface DroneEntry {
  start: string;
  stop: string;
  geojson: DronePoint;
  aglmax: number
}

export interface DronesStatus {
  count: number;
  dateTime: string;
}

export type DronesList = Array<DroneEntry>;

interface DronesState {
  dronesList: DronesList;
  status: DronesStatus;
}

const initialState: DronesState = {
  dronesList: [],
  status: { count: 0, dateTime: new Date().toISOString() }
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line no-unused-vars
export const getDronesList = createAsyncThunk('drones/fetchDrones', async () => {
  return await axiosDroneMapClient.fetchDrones()
  // return {
  //   properties: [{
  //     start: "2024-03-28T12:50:51.921Z",
  //     stop: "2024-03-28T15:50:51.921Z",
  //     geojson: {
  //       type: "Point",
  //       "coordinates": [20.915855, 52.2858]
  //     },
  //     aglmax: 120
  //   }],
  //   status: {
  //     count: 1,
  //     dateTime: "2024-03-28T12:51:39.238085083"
  //   }
  // }
})

export const dronesSlice = createSlice({
  name: 'drones',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    return builder.addCase(getDronesList.fulfilled, (state, action) => {
      state.dronesList = action.payload.properties as DronesList;
      state.status = action.payload.status;
    });
  },
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDrones = (state: RootState) => state.drones.dronesList
export const selectDronesStatus = (state: RootState) => state.drones.status

export default dronesSlice.reducer
