import { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import getModal from './utilities/getModal'
import {
  closeUniversalModal,
  selectUniversalModalOpenedList,
} from '../store/slices/universalModalSlice/universalModalSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { isEmpty } from 'lodash'
import { t } from 'i18next'

const UniversalModal: FC<{}> = () => {
  const openedModalsList = useAppSelector(selectUniversalModalOpenedList)

  const dispatch = useAppDispatch()

  const closeModal = (id: string) => {
    dispatch(closeUniversalModal({ id }))
  }

  const dialogStyles = (title: string) => {
    if (title === t('user_manual')) {
      return {
        maxWidth: '660px',
        width: '660px',
      }
    } else if (title === t('kml_download')) {
      return {
        maxWidth: '400px',
        width: '400px',
      }
    } else {
      return {
        maxWidth: '600px',
      }
    }
  }

  if (isEmpty(openedModalsList))
    return null;

  const modalProperties = { ...openedModalsList[openedModalsList.length - 1], closeModal };
  const ModalContent = getModal(modalProperties.modalType);
  if (!ModalContent)
    return null;

  return (
    <Dialog
      open={modalProperties.isOpenModal}
      onClose={(evt, reason) => {
        if (modalProperties.disableBackdropClick && reason === 'backdropClick') {
          return null
        }
        return closeModal(modalProperties.id)
      }}
      PaperProps={{
        style: dialogStyles(modalProperties.title),
      }}
    >
      {modalProperties.isCloseButtonVisible && (
        <IconButton
          aria-label="close"
          onClick={() => closeModal(modalProperties.id)}
          style={{ position: 'absolute', right: 10, top: 10 }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      )}
      {modalProperties?.title && <DialogTitle>{modalProperties.title}</DialogTitle>}
      <ModalContent {...modalProperties} />
    </Dialog>
  )
}

export default UniversalModal
