export const rodo_content_pl = `            <p align="justify">
             Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego Rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. 
             informuję, iż Administratorem danych osobowych jest Polska Agencja Żeglugi Powietrznej, ul. Wieżowa 8, 02-147 Warszawa
             . Kontakt do Inspektora Ochrony Danych: inspektorochronydanych@pansa.pl
            </p>
            <br />
            <p align="justify">
              Pani/Pana dane osobowe będą przetwarzane w celu zarejestrowania informacji o zamiarze wykonania lotu
              bezzałogowym statkiem powierzymy w związku z koniecznością zapewnienia bezpiecznej, ciągłej, płynnej
              i efektywnej żeglugi powietrznej w polskiej przestrzeni powietrznej. Przetwarzanie to jest niezbędne
              do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO), a obowiązek 
              ten wynika z Wytycznych nr 7 Prezesa Urzędu Lotnictwa Cywilnego z dnia 9 czerwca 2021 r oraz Ustawa z
              dnia 16 kwietnia 2020 r. o szczególnych instrumentach wsparcia w związku z rozprzestrzenianiem się wirusa
              SARS-CoV-2 a także w związku z wykonywaniem zadania realizowanego w interesie publicznym i w ramach
              sprawowania władzy publicznej powierzonej PAŻP (art. 6 ust. 1 lit. e RODO). Pani/Pana dane osobowe 
              możemy przetworzyć również w celu realizacji komunikacji z osobami zgłaszającymi zamiar wykonania
              lotu bezzałogowym statkiem powierzymy, a przetwarzanie to jest niezbędne do celów wynikających z
              prawnie uzasadnionych interesów realizowanych przez Administratora (art. 6 ust. 1 lit. f RODO)
              polecającym na zapewnieniu możliwości przekazania istotnych informacji osobom zgłaszającym zamiar
              wykonania lotu bezzałogowym statkiem powierzymy. Pani/Pana dane osobowe będziemy przetwarzać
              nie dłużej aniżeli 6 lat od momentu zarejestrowania lotu. 
            </p>
            <br />
            <p align="justify">
              Pani/Pana dane osobowe mogą zostać przekazane dostawcom systemów i usług IT z którymi współpracuje PAŻP, 
              Urzędowi Lotnictwa Cywilnego oraz upoważnionym podmiotom na udokumentowany wniosek np. policja. W zakresie
              wynikającym z RODO i z ograniczeniami w nim wskazanymi posiada Pani/Pan prawo dostępu do treści swoich
              danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, 
              prawo wniesienia sprzeciwu.
            </p>
            <br />
            <p align="justify">
              Ma Pani/Pan prawo wniesienia skargi do Urzędu Ochrony Danych Osobowych mieszczącego się przy ulicy Stawki 2 w Warszawie.
              Więcej informacji dostępnych pod adresem www.uodo.gov.pl.
            </p>
            <br />
            <p align="justify">
              Na podstawie Pani/Pana danych osobowych nie będzie dochodziło do podejmowania zautomatyzowanych decyzji w tym
              profilowania. 
            </p>
            <br />
            <p align="justify">
              Podanie przez Panią/Pana danych osobowych jest obligatoryjne i wynika bezpośrednio z przepisów prawa. Wykonanie
              lotu bezzałogowym statkiem powierzymy bez poinformowania o tym PAŻP jest niezgodne z prawem.
            </p>
          `

    
            
