import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import LocalStorageTTL from '../../../utilities/localStorage'
import { useTranslation } from 'react-i18next'
import { modalPropInterface } from '../../utilities/modalInterfaces'

const CustomInfo: FC<modalPropInterface> = ({ uniqueProps, id, closeModal }) => {
  const cookieName = uniqueProps?.cookieName
  const htmlContent = uniqueProps?.htmlContent
  const [isInfoAccepted, setIsInfoAccepted] = useState<{ [key: string]: string }>({})
  const [display, setDisplay] = useState<boolean>(false)
  const { handleSubmit } = useForm()
  const { t } = useTranslation()

  const acceptInfo = useCallback(() => {
    if (cookieName) {
      LocalStorageTTL.set(cookieName, true, 3600000 * 24 * 28)
    }
    setIsInfoAccepted((lastState: any) => { return { ...lastState, [id]: true } })
  }, [cookieName, id])

  useEffect(() => {
    if (cookieName && !!LocalStorageTTL.get(cookieName)) {
      acceptInfo();
    } else {
      setDisplay(true)
    }
  }, [cookieName, acceptInfo])

  useEffect(() => {
    if (isInfoAccepted[id]) {
      closeModal(id)
    }
  }, [isInfoAccepted, closeModal, id])

  const onSubmit = useCallback((data: any, event: any) => {
    event.preventDefault()
    acceptInfo();
  }, [acceptInfo])

  if (!htmlContent || !display)
    return null;

  const htmlContentArray = typeof htmlContent === 'string' ? [htmlContent] : htmlContent;

  return (
    <>
      <DialogContent style={{ overflowY: 'auto' }}>
        {htmlContentArray.map(
          (content: string, i: number) =>
            <div key={i} dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>
        <Button onClick={handleSubmit(onSubmit)}>{t('accept')}</Button>
      </DialogActions>
    </>
  )
}

export default CustomInfo
