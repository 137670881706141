import { useState } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import LanguageIcon from '@mui/icons-material/Language'
import { useTranslation } from 'react-i18next'

const MenuModal = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n.language)
  const { t } = useTranslation()

  const changeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value)
    setLang(e.target.value)
  }

  const languages = [
    { key: 'pl', label: 'Polski' },
    { key: 'en', label: 'English' },
  ]

  return (
    <Box sx={{ width: 220 }} style={{ marginTop: 10 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel>
        <Select labelId="lang-select-label" id="lang-select" value={lang} label="Language" onChange={changeLanguage}>
          {languages.map((l) => (
            <MenuItem value={l.key} key={l.key} style={{ display: lang === l.key ? 'none' : 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {lang === l.key ? <LanguageIcon /> : <CircleOutlinedIcon />}
                <span style={{ marginLeft: 10 }}>{l.label}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default MenuModal
