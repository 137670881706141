import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { conditions_content_pl } from './locales/pl/conditions_content_pl'
import { conditions_content_en } from './locales/en/conditions_content_en'
import { cookies_content_pl } from './locales/pl/cookies_content_pl'
import { cookies_content_en } from './locales/en/cookies_content_en'
import { rodo_content_pl } from './locales/pl/rodo_content_pl'
import { rodo_content_en } from './locales/en/rodo_content_en'
import { informations_content_pl } from './locales/pl/informations_content_pl'
import { informations_content_en } from './locales/en/informations_content_en'
import { geolocalization_settings_info_content_pl } from './locales/pl/geolocalization_settings_info_content_pl'
import { geolocalization_settings_info_content_en } from './locales/en/geolocalization_settings_info_content_en'

const languages = ['en', 'pl']
const browserLang = window.navigator.userLanguage || window.navigator.language

let userLanguage = languages[0]

if (browserLang.includes(languages[1])) {
  userLanguage = languages[1]
}

i18n.use(initReactI18next).init({
  fallbackLng: userLanguage,
  lng: userLanguage,
  resources: {
    en: {
      translations: {
        ...require('./locales/en/translations.json'),
        conditions_content: conditions_content_en,
        cookies_content: cookies_content_en,
        rodo_content: rodo_content_en,
        informations_content: informations_content_en,
        geolocalization_settings_info_content: geolocalization_settings_info_content_en,
      },
    },
    pl: {
      translations: {
        ...require('./locales/pl/translations.json'),
        conditions_content: conditions_content_pl,
        cookies_content: cookies_content_pl,
        rodo_content: rodo_content_pl,
        informations_content: informations_content_pl,
        geolocalization_settings_info_content: geolocalization_settings_info_content_pl,
      },
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.languages = languages

export default i18n
